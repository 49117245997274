import context from "./customerTaskContext";
import React, {useContext, useEffect, useReducer, useRef} from "react";
import reducer from "./customerTaskReducer";
import types from "./customerTaskTypes";
import {NotificationContext} from "../NotificationContext";
import {AuthContext} from "../AuthContext";
import sortContext from "../sort/sortContext";
import {useIsMounted} from "../../hooks/isMounted";

const initialState = {
	loading: false,
	customerTasks: [],
	pageInfo: {
		limit: 10,
		offset: 0,
		maxOffset: 0,
		hasNextPage: false,
		hasPreviousPage: false,
	},
	filters: {
		search: '',
		customerId: [],
		invoiced: false,
		status: []
	}
};

export default ({children}) => {
	const [state, dispatch] = useReducer(reducer, initialState);
    const {token, setToken} = useContext(AuthContext);
	const {addToast} = useContext(NotificationContext);
	const {asc, desc, sortBy} = useContext(sortContext);
	const abortController = useRef(null);

	useEffect(() => {
		dispatch({
			type: types.SET_PAGE_INFO,
			payload: {
				...state.pageInfo,
				offset: 0
			}
		});
	}, [state.pageInfo.limit]);

	useEffect(() => {
		abortController.current = new AbortController();

		(async () => {
			try {
				await getCustomerTasks();
			}
			catch (e) {
				console.error(e);
				dispatch({
					type: types.SET_LOADING
				});
			}
		})();

		return () => {
			abortController.current.abort();
		}
	}, [state.pageInfo.offset, state.pageInfo.limit, state.filters.search, state.filters.invoiced, state.filters.customerId, state.filters.status, asc, desc, sortBy]);

	// Get customers
	async function getCustomerTasks() {
		dispatch({
			type: types.SET_LOADING
		});

		const response = await fetch(process.env.REACT_APP_endPoint + '/graphql', {
			method: "POST",
			signal: abortController.current.signal,
			headers: {
				"Content-Type": "application/json",
				'Authorization': 'Bearer ' + token
			},
			body: JSON.stringify({
				query: `
					query getCustomerTasks ($pagination:PaginationInput, $filters:CustomerTaskFilters, $sort:SortInput){
					  getCustomerTasks (pagination:$pagination, filters:$filters, sort:$sort) {
						customerTasks {
						id
						name
						description
						customerId
						archived
						person
						personEmail
						dueDate
						budget
						expectedDueDate
						clockifyId
						comments
						status
						deliveryDate
						deliveryLocation
						code
						poNumber
						invoiced
						invoiceAmount
						invoiceDate
						invoiceNumber
						creationDate
						finalAmount
						estimatedInvoiceValue
						externalCosts
						internalCosts
						estimatedBonus
						customer {
							id
							fullName 
						} 
						purchaseOrder {
							id
							number
							description
						}
						purchaseOrderId
					  }
						pageInfo {
						  hasNextPage
						  hasPreviousPage
						  offset
						  limit
						  maxOffset
						}
					  }
					}
						`,
				variables: {
					pagination: {
						limit: state.pageInfo.limit,
						offset: state.pageInfo.offset
					},
					filters: {
						...state.filters,
						invoiced: state.filters.invoiced == 1 ? true: false
					},
					sort: {
						column: sortBy,
						asc,
						desc
					}
				}
			})
		});
		if (response.status === 401) {
			setToken(null);
		}
		else {
			const json = await response.json();

			if (json.errors) {
				for (let i = 0; i < json.errors.length; i++) {
					const {data} = json.errors[i];

					if (data && data.length > 0) {
						for (let j = 0; j < data.length; j++) {
							addToast({
								message: json.errors[i].message,
								description: data[j]
							});
						}
					} else {
						addToast({
							message: json.errors[i].message,
							description: ""
						});
					}
				}
				dispatch({
					type: types.SET_LOADING
				});
			} else {
				dispatch({
					type: types.SET_CUSTOMER_TASKS,
					payload: json.data.getCustomerTasks.customerTasks
				});
				dispatch({
					type: types.SET_PAGE_INFO,
					payload: json.data.getCustomerTasks.pageInfo
				})
			}
		}
	}

	// Remove customer
	async function removeCustomerTask(customerTaskId) {
		dispatch({
			type: types.SET_LOADING
		});

		const response = await fetch(process.env.REACT_APP_endPoint + '/graphql', {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				'Authorization': 'Bearer ' + token
			},
			body: JSON.stringify({
				query: `
					mutation removeCustomerTask ($customerTaskId: ID!){
						removeCustomerTask(customerTaskId:$customerTaskId) 
					}
				`,
				variables: {
					customerTaskId
				}
			})
		});
		if (response.status === 401) {
			setToken(null);
		}
		else {
			const json = await response.json();

			if (json.errors) {
				for (let i = 0; i < json.errors.length; i++) {
					const {data} = json.errors[i];

					if (data && data.length > 0) {
						for (let j = 0; j < data.length; j++) {
							addToast({
								message: json.errors[i].message,
								description: data[j]
							});
						}
					} else {
						addToast({
							message: json.errors[i].message,
							description: ""
						});
					}
				}
				dispatch({
					type: types.SET_LOADING
				});
			} else {
				if (state.customerTasks.length === 1 && state.pageInfo) {
					if (state.pageInfo.hasPreviousPage) {
						dispatch({
							type: types.SET_PAGE_INFO,
							payload: {
								...state.pageInfo,
								offset: state.pageInfo.offset - state.pageInfo.limit
							}
						});
					}
				} else {
					await getCustomerTasks();
				}
			}
		}
	}

	// Add customer
	async function createCustomerTask(customerTask) {
		dispatch({
			type: types.SET_LOADING
		});

		console.log();

		const response = await fetch(process.env.REACT_APP_endPoint + '/graphql', {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				'Authorization': 'Bearer ' + token
			},
			body: JSON.stringify({
				query: `
					mutation createCustomerTask ($customerTask:CreateCustomerTaskInput!) {
					  createCustomerTask(customerTask: $customerTask) {
						id
						name
						description
						customerId
						archived
						person
						personEmail
						dueDate
						expectedDueDate
						clockifyId
						comments
						status
						deliveryDate
						deliveryLocation
						code
						poNumber
						invoiced
						invoiceAmount
						invoiceDate
						creationDate
						finalAmount
						estimatedInvoiceValue
						externalCosts
						customer {
							id
							fullName 
						} 
						purchaseOrderId
					  }
					}
				`,
				variables: {
					customerTask: {
						...customerTask,
						estimatedInvoiceValue: customerTask.estimatedInvoiceValue === '' ? null:parseInt(customerTask.estimatedInvoiceValue),
						dueDate: customerTask.dueDate || null,
						expectedDueDate: customerTask.expectedDueDate || null
					}
				}
			})
		});
		if (response.status === 401) {
			setToken(null);
		}
		else {
			const json = await response.json();

			if (json.errors) {
				for (let i = 0; i < json.errors.length; i++) {
					const {data} = json.errors[i];

					if (data && data.length > 0) {
						for (let j = 0; j < data.length; j++) {
							addToast({
								message: json.errors[i].message,
								description: data[j]
							});
						}
					} else {
						addToast({
							message: json.errors[i].message,
							description: ""
						});
					}
				}

				dispatch({
					type: types.SET_LOADING
				});
			} else {
				abortController.current.abort();
				abortController.current = new AbortController();
				await getCustomerTasks();
			}
		}
	}

	// Edit customer
	async function editCustomerTask(customerTaskId, customerTask) {
		dispatch({
			type: types.SET_LOADING
		});

		const response = await fetch(process.env.REACT_APP_endPoint + '/graphql', {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				'Authorization': 'Bearer ' + token
			},
			body: JSON.stringify({
				query: `
					mutation editCustomerTask ($customerTaskId: ID!, $customerTask:EditCustomerTaskInput!) {
						editCustomerTask (customerTaskId:$customerTaskId, customerTask:$customerTask) {
							id
							name
							description
							customerId
							archived
							person
							personEmail
							dueDate
							budget
							expectedDueDate
							clockifyId
							comments
							status
							deliveryDate
							deliveryLocation
							code
							poNumber
							invoiced
							invoiceAmount
							invoiceDate
							invoiceNumber
							creationDate
							finalAmount
							estimatedInvoiceValue
							externalCosts
							internalCosts
							estimatedBonus
							customer {
								id
								fullName 
							} 
							purchaseOrderId
						}
					}
				`,
				variables: {
					customerTaskId,
					customerTask: {
						code: customerTask.code,
						name: customerTask.name,
						description: customerTask.description,
						poNumber: customerTask.poNumber || null,
						invoiceAmount: customerTask.invoiceAmount !== '' ? parseFloat(customerTask.invoiceAmount): null,
						finalAmount: customerTask.finalAmount !== '' ? parseFloat(customerTask.finalAmount): null,
						estimatedInvoiceValue: customerTask.estimatedInvoiceValue !== '' ? parseFloat(customerTask.estimatedInvoiceValue): null,
						invoiceDate: customerTask.invoiceDate || null,
						invoiceNumber: customerTask.invoiceNumber || null,
						invoiced: customerTask.invoiced,
						deliveryLocation: customerTask.deliveryLocation || null,
						customerId: customerTask.customerId,
						purchaseOrderId: customerTask.purchaseOrderId
					}
				}
			})
		});
		if (response.status === 401) {
			setToken(null);
		}
		else {
			const json = await response.json();

			if (json.errors) {
				for (let i = 0; i < json.errors.length; i++) {
					const {data} = json.errors[i];

					if (data && data.length > 0) {
						for (let j = 0; j < data.length; j++) {
							addToast({
								message: json.errors[i].message,
								description: data[j]
							});
						}
					} else {
						addToast({
							message: json.errors[i].message,
							description: ""
						});
					}
				}
				dispatch({
					type: types.SET_LOADING
				});
			} else {
				const newCustomerTasks = [...state.customerTasks];
				newCustomerTasks[newCustomerTasks.findIndex(value => value.id === customerTaskId)] = json.data.editCustomerTask;
				dispatch({
					type: types.SET_CUSTOMER_TASKS,
					payload: newCustomerTasks
				});
			}
		}
	}

	return (
		<context.Provider value={{
			...state,
			getCustomerTasks,
			createCustomerTask,
			editCustomerTask,
			removeCustomerTask,
			dispatch
		}}>
			{children}
		</context.Provider>
	);
};
