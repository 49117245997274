import {useContext, useEffect, useState} from "react";
import Spinner from "../../Spinner";
import customerContext from "../../../contexts/customers/customerContext";
import customerTaskContext from "../../../contexts/customerTask/customerTaskContext";
import Error from "../../alerts/Error";
import purchaseOrderContext from "../../../contexts/purchaseOrders/purchaseOrderContext";

const initialCustomerTask = {
	code: '',
	name: '',
	description: '',
	person: '',
	personEmail: '',
	customerId: '',
	estimatedInvoiceValue: '',
	dueDate: '',
	expectedDueDate: '',
	purchaseOrderId: ''
};

function AddCustomerTask() {
	const [customerTask, setCustomerTask] = useState(initialCustomerTask);
	const {loading, createCustomerTask} = useContext(customerTaskContext);
	const {customers} = useContext(customerContext);
	const {purchaseOrders} = useContext(purchaseOrderContext);
	const [error, setError] = useState(null);
	async function onSubmit(evt) {
		evt.preventDefault();
		setError(null);

		try {
			await createCustomerTask(customerTask);
		}
		catch (e) {
			console.error(e);
			setError(e.message);
		}
		setCustomerTask(initialCustomerTask);
	}

	function onChange(evt) {
		switch (evt.target.type) {
			case "checkbox":
				setCustomerTask({
					...customerTask,
					[evt.target.id]: evt.target.checked
				});
				break;
			default:
				setCustomerTask({
					...customerTask,
					[evt.target.id]: evt.target.value
				});
		}
	}

	return (
		<div>
			<button className={"btn btn-success"} data-bs-toggle={"modal"} data-bs-target={"#addCustomerTaskModal"}>Add customer task</button>
			<div className={"modal fade"} id={"addCustomerTaskModal"} tabIndex="-1" aria-labelledby="addCustomerTaskModalLabel" aria-hidden="true">
				<div className={"modal-dialog modal-lg"}>
					<div className={"modal-content"}>
						<form onSubmit={onSubmit}>
							<div className={"modal-header"}>
								<h5 className={"modal-title"} id={"addCustomerTaskModalLabel"}>Add customer task</h5>
								<button type={"button"} className={"btn-close"} data-bs-dismiss={"modal"} />
							</div>
							<div className={"modal-body"}>
								{error && <Error message={error} /> }
								<div className="row mb-3">
									<div className={"col"}>
										<label htmlFor="code" className={"form-label"}>Code:</label>
										<input type="text" id={"code"} value={customerTask.code} className={"form-control"} placeholder={"00_Other"} required={true} onChange={onChange}/>
									</div>
									<div className={"col"}>
										<label htmlFor="name" className={"form-label"}>Name:</label>
										<input type="text" id={"name"} value={customerTask.name} className={"form-control"} placeholder={"WBQA policy violation: answer passage"} required={true} onChange={onChange}/>
									</div>
								</div>
								<div className="mb-3">
									<label htmlFor="description" className={"form-label"}>Description:</label>
									<textarea id={"description"} className={"form-control"} placeholder={"Have to be invoiced by GlobalMe -- WBQA policy violation: answer passage"} required={true} value={customerTask.description} onChange={onChange}/>
								</div>
								<div className="row mb-3">
									<div className={"col"}>
										<label htmlFor="person" className={"form-label"}>Contact person:</label>
										<input type="text" id={"person"} value={customerTask.person} className={"form-control"}  required={false} onChange={onChange}/>
									</div>
									<div className={"col"}>
										<label htmlFor="personEmail" className={"form-label"}>Contact person email address:</label>
										<input type="email" id={"personEmail"} value={customerTask.personEmail} className={"form-control"}  required={false} onChange={onChange}/>
									</div>
								</div>
								<div className={"mb-3"}>
									<label htmlFor="customerId" className={"form-label"}>Customer:</label>
									<select id="customerId" className={"form-select"} required={true} value={customerTask.customerId} onChange={onChange}>
										<option value="">Select an option</option>
										{customers.filter(value => !value.archived).map(value => {
											return <option key={value.id} value={value.id}>{value.fullName}</option>;
										})}
									</select>
								</div>
								<div className={"mb-3"}>
									<label htmlFor="purchaseOrderId" className={"form-label"}>PO number:</label>
									<select id="purchaseOrderId" className={"form-select"} required={true} value={customerTask.purchaseOrderId} onChange={onChange}>
										<option value="">Select an option</option>
										{purchaseOrders.map(value => {
											return <option key={value.id} value={value.id}>{`${value.number} -> ${value.description}`}</option>;
										})}
									</select>
								</div>
								<div className="row mb-3">
									<div className={"col"}>
										<label htmlFor="dueDate" className={"form-label"}>Due date for quoting:</label>
										<input type="date" id={"dueDate"} className={"form-control"} value={customerTask.dueDate} required={false} onChange={onChange}/>
									</div>
									<div className={"col"}>
										<label htmlFor="expectedDueDate" className={"form-label"}>Expected due date:</label>
										<input type="date" id={"expectedDueDate"} className={"form-control"} value={customerTask.expectedDueDate} required={false} onChange={onChange}/>
									</div>
								</div>
								<div className="mb-3">
									<label htmlFor="estimatedInvoiceValue" className={"form-label"}>Estimated invoice value:</label>
									<input type="number" id={"estimatedInvoiceValue"} value={customerTask.estimatedInvoiceValue} className={"form-control"} required={false} onChange={onChange}/>
								</div>
							</div>
							<div className={"modal-footer justify-content-between"}>
								<button type={"button"} className={"btn btn-secondary"} data-bs-dismiss={"modal"}>Close</button>
								<button type={"submit"} className={"btn btn-success"}>{loading ? <Spinner />: 'Add customer task'}</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AddCustomerTask;
