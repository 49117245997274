import EditPurchaseOrder from "./EditPurchaseOrder";
import RemovePurchaseOrder from "./RemovePurchaseOrder";

function PurchaseOrderRow({purchaseOrder}) {
	return (
		<tr>
			<td>{purchaseOrder.number}</td>
			<td>{purchaseOrder.description}</td>
			<td>{purchaseOrder.totalValue.toFixed(2)}</td>
			<td>{purchaseOrder.currency.name}</td>
			<td><a href={`${process.env.REACT_APP_endPoint}/purchaseOrders/downloadPDF/${purchaseOrder.id}`}>
				{purchaseOrder.pdfFileName}
			</a></td>
			<td>{purchaseOrder.valueConsumed.toFixed(2)}</td>
			<td>{purchaseOrder.valueRemaining.toFixed(2)}</td>
			<td>{purchaseOrder.customer.fullName}</td>
			<td>{purchaseOrder.comments}</td>

			<td>
				<div className={"d-flex gap-2"}>
					<EditPurchaseOrder purchaseOrder={purchaseOrder}/>
					<RemovePurchaseOrder purchaseOrder={purchaseOrder}/>
				</div>
			</td>
		</tr>
	);
}

export default PurchaseOrderRow;
