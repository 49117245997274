import {createContext} from "react";

export default createContext({
	purchaseOrders: [],
	loading: false,
	getPurchaseOrders: () => {},
	createPurchaseOrder: () => {},
	editPurchaseOrder: () => {},
	removePurchaseOrder: () => {},
	pageInfo: {
		limit: 10,
		offset: 0,
		maxOffset: 0,
		hasNextPage: false,
		hasPreviousPage: false,
	},
	filters: {
		consumed: "0"
	},
	dispatch: () => {}
});
